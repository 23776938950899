import {createPortal, forwardRef} from 'preact/compat';
import {useEffect, useImperativeHandle, useState} from 'preact/hooks';

import {useRootProvider} from '~/foundation/RootProvider/hooks';
import {isoDocument} from '~/utils/document';

import {ShopIcon} from '../icons/ShopIcon';
import {PortalProvider} from '../PortalProvider/PortalProvider';

export interface ToastElement {
  show: (text: string) => void;
}

export const Toast = forwardRef<ToastElement>((_, ref) => {
  const {instanceId} = useRootProvider();
  const [content, setContent] = useState('');
  const [visible, setVisible] = useState(false);

  useImperativeHandle(ref, () => {
    return {
      show: (text) => {
        setContent(text);
        setVisible(true);
      },
    };
  });

  useEffect(() => {
    if (visible) {
      const timer = setTimeout(() => {
        setVisible(false);
      }, 3500);

      return () => {
        clearTimeout(timer);
      };
    } else {
      const timer = setTimeout(() => {
        setContent('');
      }, 201);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [visible]);

  return createPortal(
    <PortalProvider instanceId={instanceId} type="toast" variant="default">
      <div
        className="fixed bottom-8 left-0 right-0 z-max mx-auto flex max-w-94 translate-y-16 items-center justify-between gap-x-4 rounded-md bg-purple-primary px-4 py-3 text-white opacity-0 transition-all duration-200 ease-cubic-in-out data-[visible=true]_translate-y-0 data-[visible=true]_opacity-100 motion-reduce_translate-y-0 motion-reduce_transition-opacity sm_bottom-0 sm_max-w-none sm_rounded-none"
        data-visible={visible}
      >
        <span className="text-ellipsis whitespace-nowrap font-sans text-caption text-white">
          {content}
        </span>
        <ShopIcon className="h-4 w-auto flex-shrink-0" />
      </div>
    </PortalProvider>,
    isoDocument.body,
  );
});
