import {useCallback, useEffect, useMemo, useRef, useState} from 'preact/hooks';

import {AuthorizeIframe} from '~/components/Authorize/Iframe';
import {Button} from '~/components/Button/Button';
import {ShopIcon} from '~/components/icons/ShopIcon';
import type {ToastElement} from '~/components/Toast/Toast';
import {Toast} from '~/components/Toast/Toast';
import {useBugsnag} from '~/foundation/Bugsnag/hooks';
import {useI18n} from '~/foundation/I18n/hooks';
import {useMonorail} from '~/foundation/Monorail/hooks';
import {RootProvider} from '~/foundation/RootProvider/RootProvider';
import {useAuthorizeUrl} from '~/hooks/useAuthorizeUrl';
import {useDispatchEvent} from '~/hooks/useDispatchEvent';
import {useElementEventListener} from '~/hooks/useElementEventListener';
import {useEmailListener} from '~/hooks/useEmailListener';
import type {AnalyticsContext} from '~/types/analytics';
import type {SdkErrorCode} from '~/types/authorize';
import type {
  AuthorizeErrorEvent,
  CompletedEvent,
  StartEvent,
} from '~/types/event';
import type {ShopActionType} from '~/types/flow';
import type {IframeElement} from '~/types/iframe';
import {exchangeLoginCookie} from '~/utils/cookieExchange';
import {isoDocument} from '~/utils/document';
import register from '~/utils/register';
import {calculatePopupCenter} from '~/utils/windoid';
import {isoWindow} from '~/utils/window';

import {getAnalyticsProps} from './monorail';
import type {
  ShopLoginButtonEventMap,
  ShopLoginButtonProps,
  ShopLoginButtonWebComponentProps,
} from './types';

// Flows to show a Toast component on
const TOAST_FLOWS: AnalyticsContext[] = ['loginWithShop'];

const ShopLoginButtonInternal = ({
  action,
  activatorId,
  analyticsContext = 'loginWithShop',
  anchorTo,
  autoOpen,
  email,
  emailInputSelector,
  hideButton,
  persistAfterSignIn = true,
  proxy = false,
  uxMode = 'iframe',
  version,
  ...props
}: ShopLoginButtonProps) => {
  const {notify} = useBugsnag();
  const {trackPageImpression, trackUserAction} = useMonorail();
  const {translate} = useI18n();
  const [contentVisible, setContentVisible] = useState(true);
  const dispatchEvent = useDispatchEvent();

  const activatorElementRef = useRef<HTMLElement | null>(
    activatorId ? isoDocument.getElementById(activatorId) : null,
  );
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const iframeRef = useRef<IframeElement | null>(null);
  const toastRef = useRef<ToastElement | null>(null);
  const windoidRef = useRef<WindowProxy | null>(null);
  const [error, setError] = useState<SdkErrorCode>();

  const isWindoid = useMemo(() => uxMode === 'windoid', [uxMode]);

  const {getSubmittedEmail, updateEmailToPost} = useEmailListener({
    autoOpen,
    email,
    emailInputSelector,
    iframeRef,
  });

  const elementEventListeners = useMemo(() => {
    const start = async ({detail}: StartEvent) => {
      setError(undefined);
      const email = detail.email;
      updateEmailToPost(email);
    };
    return {
      requestShow: start,
      start,
    };
  }, [updateEmailToPost]);

  useElementEventListener<ShopLoginButtonEventMap>(elementEventListeners);

  useEffect(() => {
    if (action) {
      if (action === 'follow') {
        // eslint-disable-next-line no-console
        console.warn(
          '[Sign in with Shop] The action prop is deprecated, please use <shop-follow-button> instead.',
        );
      } else {
        // eslint-disable-next-line no-warning-comments
        // TODO: We removed the bugsnag event since it was extremely noisy.
        // We can iterate on how to deal with invalid configs in https://github.com/Shopify/shop-identity/issues/3519.
        // eslint-disable-next-line no-console
        console.warn(
          `[Sign in with Shop] Received deprecated attribute: action, "${action}"`,
        );
      }
    }
  }, [action, notify]);

  useEffect(() => {
    if (!hideButton) {
      trackPageImpression({
        page: 'SIGN_IN_WITH_SHOP_BUTTON',
      });
    }
  }, [hideButton, trackPageImpression]);

  const handleComplete = useCallback(
    async ({email, loggedIn, shouldFinalizeLogin}: CompletedEvent) => {
      if (!persistAfterSignIn) {
        setContentVisible(false);
      }

      if (TOAST_FLOWS.includes(analyticsContext)) {
        let content = translate('shopLoginButton.toast.default');

        if (email) {
          content = translate('shopLoginButton.toast.user', {user: email});
        }

        toastRef.current?.show(content as string);
      }

      if (loggedIn && shouldFinalizeLogin) {
        await exchangeLoginCookie(props.storefrontOrigin, notify);
        /**
         * TODO: In the future we will publish an event to shop hub to create a user session.
         *
         * Issue: https://github.com/Shopify/shop-identity/issues/2859
         * Pull request: https://github.com/Shopify/shop-js/pull/2363
         */
      }
    },
    [
      analyticsContext,
      notify,
      persistAfterSignIn,
      props.storefrontOrigin,
      translate,
    ],
  );

  const openWindoid = useCallback(
    (authorizeUrl: string): void => {
      const values = calculatePopupCenter(350, 450);

      windoidRef.current = isoWindow.open(
        authorizeUrl,
        'SignInWithShop',
        `popup,width=${values.width},height=${values.height},top=${values.top},left=${values.left}`,
      );

      isoWindow.addEventListener('message', (payload) => {
        if (payload.data.type === 'completed') {
          handleComplete(payload.data as any);
          dispatchEvent('completed', payload.data, true);
          windoidRef.current?.close();
        }
      });
    },
    [handleComplete, dispatchEvent],
  );

  // eslint-disable-next-line no-warning-comments
  // TODO: Remove this once the Installments components are ported to Preact
  // https://github.com/Shopify/shop-server/issues/82525
  const isPrequalFlow = analyticsContext === 'loginWithShopPrequal';
  const prequalProps = isPrequalFlow
    ? {
        disableSignUp: false,
        flow: 'prequal' as ShopActionType,
        flowVersion: 'unspecified',
        hideCopy: false,
        isCompactLayout: false,
        proxy: true,
      }
    : {};

  const shouldProxy = useMemo(() => {
    if (version !== undefined) {
      // eslint-disable-next-line no-warning-comments
      // TODO: We removed the DeprecatedAttributeError bugsnag event, since it was extremely noisy.
      // We can iterate on how to deal with invalid configs in https://github.com/Shopify/shop-identity/issues/3519.
      // eslint-disable-next-line no-console
      console.warn(
        `[Sign in with Shop] Received deprecated attribute: version, "${version}"`,
      );
    }

    return proxy || version === '2';
  }, [proxy, version]);
  const src = useAuthorizeUrl({
    analyticsContext,
    error,
    ...props,
    proxy: shouldProxy,
    uxMode,
    ...prequalProps,
  });

  function onError({code, email, message}: AuthorizeErrorEvent): void {
    dispatchEvent('error', {
      code,
      email,
      message,
    });

    if (code === 'retriable_server_error') {
      if (error === code) {
        iframeRef.current?.reload();
      }
      setError(code);
    }
  }

  const handleButtonClick = useCallback(() => {
    trackUserAction({
      userAction: 'SIGN_IN_WITH_SHOP_BUTTON_CLICK',
    });

    if (isWindoid) {
      openWindoid(src);
    }
  }, [trackUserAction, isWindoid, openWindoid, src]);

  const handlePromptContinue = useCallback(() => {
    trackUserAction({
      userAction: 'SIGN_IN_WITH_SHOP_PROMPT_CONTINUE_CLICK',
    });

    iframeRef?.current?.close({
      dismissMethod: 'windoid_continue',
      reason: 'user_prompt_continue_clicked',
    });
    const email = getSubmittedEmail();
    const newSrc = email ? `${src}&login_hint=${email}` : src;
    openWindoid(newSrc);
  }, [getSubmittedEmail, openWindoid, src, trackUserAction]);

  const button =
    hideButton || activatorElementRef.current || !contentVisible ? null : (
      <Button onClick={handleButtonClick} ref={buttonRef} className="m-auto">
        <span className="cursor-pointer whitespace-nowrap px-11 py-4 font-sans text-button-large text-white">
          {translate('shopLoginButton.continue', {
            shop: <ShopIcon className="relative inline-block h-4 w-auto" />,
          })}
        </span>
      </Button>
    );

  const activator = activatorElementRef.current
    ? activatorElementRef
    : buttonRef;
  const iframeActivator = isWindoid ? undefined : activator;
  const iframeSrc = isWindoid ? `${src}&ux_role=prompt` : src;
  const modalHeaderTitle = isWindoid
    ? (translate('shopLoginButton.title', {defaultValue: ''}) as string)
    : '';

  return (
    <>
      {button}
      <AuthorizeIframe
        activator={iframeActivator}
        anchorTo={anchorTo}
        autoOpen={autoOpen && !windoidRef.current}
        modalHeaderTitle={modalHeaderTitle}
        onComplete={handleComplete}
        onError={onError}
        onPromptContinue={handlePromptContinue}
        proxy={shouldProxy}
        ref={iframeRef}
        src={iframeSrc}
        variant="default"
      />
      <Toast ref={toastRef} />
    </>
  );
};

const getFeatureDictionary = async (locale: string) =>
  // Temporary adding an await here so that the i18n-dynamic-import-replacer
  // replaces the import statement with the correct file.
  // https://github.com/Shopify/shop-identity/issues/2814
  // eslint-disable-next-line no-return-await
  await import(`./translations/${locale}.json`);

register<ShopLoginButtonProps>(
  ({element, ...props}) => (
    <RootProvider
      element={element}
      featureName="ShopLoginButton"
      getFeatureDictionary={getFeatureDictionary}
      monorailProps={getAnalyticsProps(props)}
    >
      <ShopLoginButtonInternal {...props} />
    </RootProvider>
  ),
  {
    methods: ['requestShow', 'start'],
    name: 'shop-login-button',
    props: {
      action: 'string',
      activatorId: 'string',
      analyticsContext: 'string',
      anchorTo: 'string',
      apiKey: 'string',
      autoOpen: 'boolean',
      avoidPayAltDomain: 'boolean',
      checkoutVersion: 'string',
      clientId: 'string',
      codeChallenge: 'string',
      codeChallengeMethod: 'string',
      consentChallenge: 'string',
      disableSignUp: 'boolean',
      email: 'string',
      emailInputSelector: 'string',
      flow: 'string',
      flowVersion: 'string',
      hideButton: 'boolean',
      keepModalOpen: 'boolean',
      persistAfterSignIn: 'boolean',
      popUpFeatures: 'string',
      popUpName: 'string',
      proxy: 'boolean',
      redirectType: 'string',
      redirectUri: 'string',
      requireVerification: 'boolean',
      responseMode: 'string',
      responseType: 'string',
      scope: 'string',
      source: 'string',
      state: 'string',
      uxMode: 'string',
      version: 'string',
    },
    shadow: 'open',
  },
);

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace preact.JSX {
    interface IntrinsicElements {
      ['shop-login-button']: ShopLoginButtonWebComponentProps;
    }
  }
}

export function ShopLoginButton(props: ShopLoginButtonWebComponentProps) {
  return <shop-login-button {...props} />;
}
